<template>
  <div id="pool-new-project">
    <Jumbotron
      header="Pools - New Projects"
      lead="Let's start building your dream from the deep-end up."
      bgImagePath="/media/static/water-wallpaper.jpg"
    >
    </Jumbotron>

    <b-container>
      <b-row>
        <b-col md="4">
          <b-img fluid src="../../assets/images/permits.jpg"></b-img>
        </b-col>
        <b-col md="8">
          <h2 class="title">
            Start on the Right Foot<span class="ml-3"
              ><i class="fas fa-shoe-prints"></i
            ></span>
          </h2>
          <p class="long-text">
            Choosing the right pool builder is an important first step. The more
            complicated your project becomes, the more important it is to select
            a company who can deliver a product that both meets your
            expectations and your budget. We invite you to
            <b-link href="/contact">schedule</b-link> an appointment with AB
            Pools so that we may show you why we think we're pretty good at what
            we love to do.
          </p>
          <p class="long-text">
            While designing a pool and backyard living area can be overwhelming,
            we make it our job to guide you through the process so you can worry
            about the really hard choices... like what color bathing suit to
            wear on opening day. <b-link href="/contact">Call us</b-link> today!
          </p>
        </b-col>
      </b-row>
      <b-row class="pt-5">
        <b-col md="7">
          <h2 class="title">
            <span class="mr-3"><i class="fas fa-flag-checkered"></i></span
            >Getting Started
          </h2>
          <p class="long-text">
            Getting a pool can be complicated, but sometimes its about knowing
            what questions to ask to help get started. here are a few that we
            think should be at the top of your list. We've also included some
            resources that we think are useful.
          </p>
          <ul class="fa-ul questions">
            <li>
              <span class="fa-li" style="color:#307181">
                <i class="fas fa-question"></i>
              </span>
              How much space do you want or have to devote to your pool? Pools
              can come in all shapes and sizes, so the sky is the limit. We
              won't pretend to be the only ones who can make a great pool, so
              <b-link href="https://www.houzz.com/photos/query/swimming-pools"
                >take a look</b-link
              >
              to get some ideas!
            </li>
            <li>
              <span class="fa-li" style="color:#307181">
                <i class="fas fa-question"></i>
              </span>
              What is your budget for the project? Can you envision segmenting
              your backyard living area into phases to help with budget
              constraints?
            </li>
            <li>
              <span class="fa-li" style="color:#307181">
                <i class="fas fa-question"></i>
              </span>
              Who will use the pool? This seems like an easy one, but for some
              this may be a consideration worth exploring. Differing ages and
              activities may affect your design.
            </li>
            <li>
              <span class="fa-li" style="color:#307181">
                <i class="fas fa-question"></i>
              </span>
              What kind of pool would you like? This is an age-old question and
              it seems everyone has an opinion. We specialize in vinyl (liner)
              and fiberglass pools and see
              <b-link
                href="https://www.bobvila.com/articles/2464-swimming-pools-101"
                >pros and cons</b-link
              >
              with each.
            </li>
          </ul>
          <p class="long-text">
            We know... these only scratch the surface. But don't fret! We are
            here to help you out. Just give us a ring, email, or
            <b-link href="/contact">direct message</b-link> on our site so we
            can help you get started. Its free, and we happen to think it'll be
            worth your time.
          </p>
        </b-col>
        <b-col md="5" class="d-flex align-items-center">
          <b-img fluid src="../../assets/images/execution.jpg"></b-img>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="pt-5">
      <b-row class="text-center">
        <b-col>
          <h2 class="title">
            Gallery<span class="ml-3"><i class="fas fa-images"></i></span>
          </h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p>
            We know a picture is worth a thousand words. So here's a few of our
            past projects that we've enjoyed so much that we wanted to share
            with you. We hope to show yours too one day (with your permission of
            course!).
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-for="image in images" :key="image.id" md="3" class="py-3">
          <b-img thumbnail fluid :src="image.path"></b-img>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { HTTP } from "../../http-common";
import Jumbotron from "@/components/Jumbotron.vue";

export default {
  name: "New-Projects",

  components: {
    Jumbotron
  },

  data() {
    return {
      images: []
    };
  },

  mounted: function() {
    this.$nextTick(function() {
      HTTP.get("pools/images/new").then(response => {
        var payload = response.data;
        this.images = payload;
      });
    });
  },

  methods: {}
};
</script>

<style scoped lang="scss">

.long-text {
  font-size: $base-font-size * 0.9;
  font-family: "Lato", sans-serif;
}

.title {
  color: #307181;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

a {
  color: #307181;

  &:hover {
    text-decoration: none;
    font-weight: bold;
  }
}

.questions {
  // font-family: 'Rock Salt', sans-serif;
  font-size: $base-font-size * 0.75;
}
</style>
